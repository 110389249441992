import { Card, CardActionArea, Fade, IconButton } from "@mui/material";
// For previews
import { StyledCardContent, StyledTag, StyledTitleTag, StyledTagsDiv, StyledImageDiv, StyledMainImage } from "./ProjectCard.styles";
// For detailed view
import {
  StyledPlaceholderDiv,
  StyledDetailsDiv,
  StyledGridItem,
  StyledDetailsTitle,
  StyledDescriptionDiv,
  StyledModal,
  StyledDetailsGrid,
  StyledStepperDiv,
  StyledArrowButtonDiv,
  StyledExitButton,
  StyledDetailsImagesThumbButton,
  StyledAnchorLink,
  StyledAnchorDiv,
  StyledVideoEmbed,
  StyledThumbsDiv,
  StyledDetailsImagesThumbImg
} from "./ProjectCard.styles";
import { ArrowLeftSharp, ArrowRightSharp, CloseSharp, OpenInNewSharp } from "@mui/icons-material";

import Grid from '@mui/material/Unstable_Grid2';
import { StyledToolTip } from "../home/HomePage.styles";
import { useState, useEffect, useCallback, useRef } from 'react';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import useElementOnScreen from "../../commons/useElementOnScreen";

const ProjectCard = ({ className, name, tags, category, index, isMenuClicked, setIsMenuClicked, detailedImages, description, link, selectedProjectIndex, setSelectedProjectIndex, maxProjectCount, setIsModalOpen  }) => {
  // For opening and closing modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // For stepping between images
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = detailedImages.length - 1;
  const handleNext = () => {
    if (activeStep === maxSteps) {
      setActiveStep(0);
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(maxSteps);
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleClickSpecific = index => setActiveStep(index);

  useEffect(() => {
    if (detailedImages[activeStep]) {
      checkMediaType(detailedImages[activeStep][0]);
    }
  }, [activeStep, detailedImages, name]);

  // For stepping between projects
  const goToNextProject = () => {
    if (index < maxProjectCount && index !== maxProjectCount) {
      setIsModalOpen(true);
      setSelectedProjectIndex(index + 1);
    }
  }

  const goToLastProject = () => {
    if (index > 0) {
      setIsModalOpen(true);
      setSelectedProjectIndex(index - 1);
    }
  }

  useEffect(() => {
    if (selectedProjectIndex === index) {
      handleOpen();
    }
    else {
      handleClose();
    }
  }, [selectedProjectIndex, index])

  // For image zoom-in
  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty("transform", value);
    }
  }, []);

  // Test scroll animate
  const [showCard, setShowCard] = useState(false);
  const[ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.6
  })

  useEffect(() => {
    if (isVisible && !showCard) {
      setShowCard(true);
    }
  }, [isVisible, showCard]);

  // Check if selected thumbnail is image or video
  const [selectedIsImage, setSelectedIsImage] = useState(true);
  const checkMediaType = (type) => {
    if (type === "image") {
      setSelectedIsImage(true);
    }
    else if (type === "video") {
      setSelectedIsImage(false);
    }
  }

  // For image scroll
  const [clickedThumb, setClickedThumb] = useState(null);
  useEffect(() => {
    if (clickedThumb) {
      clickedThumb.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }, [clickedThumb]);

  return (
    <Card sx={{ maxWidth: 345, boxShadow: 0 }} className={showCard ? `${className} show-card lazy` : `${className} lazy`} ref={containerRef}>
      <CardActionArea onClick={() => { handleOpen(); setIsModalOpen(true);}}>
        <StyledCardContent>
          <div>
            <StyledTagsDiv index={index} isMenuClicked={isMenuClicked}>
              <StyledTag mainTag>{category}</StyledTag>
              { 
                tags.map((prop, index) =>
                  <StyledTag key={`${prop}-${index}`}>{prop}</StyledTag>
                )
              }
            </StyledTagsDiv>
            <div>
              <StyledTitleTag index={index} isMenuClicked={isMenuClicked}>
                {name}
              </StyledTitleTag>
            </div>
          </div>
        </StyledCardContent>
      </CardActionArea>
      
      <StyledModal
        open={open}
        onClose={() => {
          handleClose();
          setSelectedProjectIndex();
          setIsMenuClicked(true);
          setIsModalOpen(false);
        }}
        aria-labelledby={`modal-modal-title-${name}`}
        aria-describedby={`modal-modal-description-${name}`}
        sx={{
          '& .MuiModal-backdrop': {
              backgroundColor: 'rgba(255, 255, 255, 0);',
          },
      }}
      >
        <StyledDetailsDiv>
          <StyledArrowButtonDiv>
            <IconButton
              onClick={goToLastProject}
              sx={ index === 0 ? {borderRadius: 0, marginRight: 1, opacity: 0} : {borderRadius: 0, marginRight: 1}}
              disabled={ index === 0 ? true : false }
            >
              <ArrowLeftSharp style={{fill: "#000000", fontSize: 40}} />
            </IconButton>
          </StyledArrowButtonDiv>
          <StyledDetailsGrid container spacing={2}>
            <Grid xs={12} md={7}>
              <StyledPlaceholderDiv>
                  <StyledDetailsTitle id={`modal-modal-title-${name}`}>
                    <span>{name}</span>
                    <StyledExitButton
                      variant="text"
                      onClick={() => { handleClose(); setIsModalOpen(false); }}
                      wide={0}
                      skinny={1}
                    >
                      <CloseSharp style={{fill: "#000000", fontSize: 20}} />
                    </StyledExitButton>
                  </StyledDetailsTitle>
                  {
                    selectedIsImage
                    ? <StyledToolTip
                    title="Double-tap to zoom in and out on desktop."
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 200 }}
                    componentsProps={{ tooltip: { sx: { borderRadius: 0, background: "transparent", color: "#000000", fontSize: 12, textAlign: "center" } } }}
                    slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -45] } } ] } }}
                    >
                    <StyledImageDiv>
                        <QuickPinchZoom
                          onUpdate={onUpdate}
                          maxZoom={4}
                          minZoom={1}
                          wheelScaleFactor={1300}
                          key={`${name}-image-${activeStep}`}
                          doubleTapToggleZoom
                          enforceBoundsDuringZoom
                          centerContained
                        >
                          <StyledMainImage ref={imgRef} src={detailedImages[activeStep] ? detailedImages[activeStep][1] : 'https://placehold.co/400'} alt={`${name}`} />
                        </QuickPinchZoom>
                    </StyledImageDiv>
                  </StyledToolTip>
                  : <StyledImageDiv>
                      <StyledVideoEmbed
                        src={detailedImages[activeStep] ? detailedImages[activeStep][2] : 'https://placehold.co/400'}
                        title="YouTube video player"
                        allow="accelerometer; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                      />
                    </StyledImageDiv>
                  }
                  <StyledStepperDiv>
                  <IconButton
                    onClick={handleBack}
                    sx={{borderRadius: 0, marginRight: 1}}
                  >
                    <ArrowLeftSharp style={{fill: "#000000", fontSize: 30}} />
                  </IconButton>
                  <StyledThumbsDiv>
                    { 
                      detailedImages ? detailedImages.map((prop, index) =>
                        <StyledDetailsImagesThumbButton
                          key={`${name}-image-${index}`}
                          handleClickSpecific={handleClickSpecific}
                          index={index}
                          name={name}
                          checkMediaType={checkMediaType}
                          setClickedThumb={setClickedThumb}
                          activeStep={activeStep}
                          selectedThumb={index === activeStep ? true : false}
                          prop={prop}
                        >
                          <StyledDetailsImagesThumbImg src={prop ? prop[1] : 'https://placehold.co/400'} alt={`${name}-image-${index}`} />
                        </StyledDetailsImagesThumbButton>
                      )
                      : null
                    }
                  </StyledThumbsDiv>
                  <IconButton
                    onClick={handleNext}
                    sx={{borderRadius: 0, marginLeft: 1}}
                  >
                    <ArrowRightSharp style={{fill: "#000000", fontSize: 30}} />
                  </IconButton>
                  </StyledStepperDiv>
                </StyledPlaceholderDiv>
            </Grid>
            <StyledGridItem xs={12} md={5}>
              <StyledPlaceholderDiv id={`modal-modal-description-${name}`}>
                  <StyledDetailsTitle description>
                    <span>Description</span>
                    <StyledExitButton
                      variant="text"
                      onClick={() => { handleClose(); setIsModalOpen(false); }}
                      wide={1}
                      skinny={0}
                    >
                      <CloseSharp style={{fill: "#FFFFFF", fontSize: 20}} />
                    </StyledExitButton>
                  </StyledDetailsTitle>
                  <StyledDescriptionDiv link={ link ? true : false }>
                    <StyledTagsDiv index={index} isMenuClicked={isMenuClicked} description>
                      <StyledTag mainTag description>{category}</StyledTag>
                      { 
                        tags.map((prop, index) =>
                          <StyledTag key={`${prop}-${index}`} description>{prop}</StyledTag>
                        )
                      }
                    </StyledTagsDiv>
                    {
                      description.map((prop, pIndex) =>
                        <p key={`${index}-p-${pIndex}`}>{prop}</p>
                      )
                    }
                  </StyledDescriptionDiv>
                  
                    {
                      link && category !== "Game Development" ? <StyledAnchorDiv><StyledAnchorLink href={link} target="_blank" rel="noreferrer">Go to Website <OpenInNewSharp fontSize="small" /></StyledAnchorLink></StyledAnchorDiv> : null
                    }
                    {
                      link && category === "Game Development" ? <StyledAnchorDiv><StyledAnchorLink href={link} target="_blank" rel="noreferrer">Download Game</StyledAnchorLink></StyledAnchorDiv> : null
                    }
              </StyledPlaceholderDiv>
            </StyledGridItem>
          </StyledDetailsGrid>
          <StyledArrowButtonDiv>
            <IconButton
              onClick={goToNextProject}
              sx={ index === maxProjectCount ? {borderRadius: 0, marginLeft: 1, opacity: 0} : {borderRadius: 0, marginLeft: 1}}
              disabled={ index === maxProjectCount ? true : false }
            >
              <ArrowRightSharp style={{fill: "#000000", fontSize: 40}} />
            </IconButton>
          </StyledArrowButtonDiv>
        </StyledDetailsDiv>
      </StyledModal>
    </Card>
  );
}

/* <div className={className}>
<div>
  {name}
</div>
</div> */

export default ProjectCard;