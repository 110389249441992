import { StyledGiantBox } from "../../commons/Content.styles";
import {
  StyledAboutDiv,
  StyledAboutTitle,
  StyledPlaceholderImg,
  StyledHeaderText,
  StyledIntroDiv,
  StyledAboutGridItem,
  StyledDivider,
  StyledSkillItem,
  StyledSpan,
  GridDiv,
  GridDivUnit,
  TechDiv,
  TechDivUnit,
  StyledResumeButton
} from "./AboutPage.styles";
import { StyledToolTip } from "../home/HomePage.styles";
import { Fade } from "@mui/material";
import { OpenInNewSharp } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';

const AboutPage = ({ isMenuClicked }) => {
  const softSkills = [
    "Takes initiative on projects",
    "Works well in either a collaborative setting or solo",
    "Thrives on problem-solving",
    "Communicates ideas effectively",
    "Eager to learn"
  ];

  const techSkills = [
    "HTML",
    "CSS",
    "JavaScript",
    "jQuery",
    "PHP",
    "SQL (MySQL, Oracle)",
    "NoSQL (MongoDB)",
    "React.js",
    "Node.js",
    "Figma",
    "Adobe CC"
  ];

  const colors = ["#98E0FF", "#7ED8FF", "#FFA3DA", "#FF8CD1", "#FFD568"];

  return (
    <StyledGiantBox isMenuClicked={isMenuClicked} justifyContent={'center'}>
      <StyledAboutDiv>
        <StyledAboutTitle>About</StyledAboutTitle>
        <div>
          <Grid container spacing={2}>
            <StyledAboutGridItem index={0} move={'end'} sm={12} md={6} lg={6} xl={4} isMenuClicked={isMenuClicked} alwaysAppear>
              <StyledPlaceholderImg />
            </StyledAboutGridItem>
            <StyledAboutGridItem index={1} move={'space-between'} sm={12} md={6} lg={6} xl={4} isMenuClicked={isMenuClicked} alwaysAppear>
              <StyledIntroDiv>
                <StyledHeaderText fontSize={2}>Nice to meet you,</StyledHeaderText>
                <StyledHeaderText fontSize={3}>I'm Alyssa Villanueva!</StyledHeaderText>
              </StyledIntroDiv>
              <StyledSpan>I’m a full-stack developer based in Victoria, British Columbia with a focus on front end development. My passion is creating websites that not only speak to a client’s brand identity, but also provide end users a smooth and engaging experience. With my experience as a long-time digital artist combined with my technical skills, I can help make your site look and feel pleasing.</StyledSpan>
            </StyledAboutGridItem>
            <StyledAboutGridItem index={2} move={'end'} sm={12} md={12} lg={12} xl={4} isMenuClicked={isMenuClicked} alwaysAppear>
              <StyledHeaderText fontSize={2}>Background</StyledHeaderText>
              <p>I’ve been a hobbyist digital artist for more than a decade, mostly creating art derived from pieces of media that I like including shows and video games. In 2022, I attended Camosun College for their Interactive Media Development program originally to pursue game development. Instead, I fell in love with web development during my time in the program where I learned how to code in different languages and how to apply my art skills for creating effective user interfaces.</p>
              <p>My favourite hobbies are playing video games and painting. On sunny days, I also like to go fishing and crabbing!</p>
            </StyledAboutGridItem>
          </Grid>
          <StyledDivider />
          <Grid container spacing={2}>
            <StyledAboutGridItem index={3} move={'start'} sm={12} md={12} lg={12} xl={4} >
              <GridDiv>
              <GridDivUnit>
                <StyledHeaderText fontSize={2}>Resume</StyledHeaderText>
                <StyledToolTip
                  title={"Click to view resume in a new tab"}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 200 }}
                  componentsProps={{ tooltip: { sx: { borderRadius: 0, background: "#000000", color: "#FFFFFF", fontSize: 12, textAlign: "center" } }, arrow: { sx: { color: "#000000"}} }}
                  placement="right"
                  isMenuClicked={isMenuClicked}
                  arrow
                >
                  <StyledResumeButton
                    href="https://www.alyssavillanueva.info/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>View Resume </span>
                    <OpenInNewSharp fontSize="small" />
                  </StyledResumeButton>
                </StyledToolTip>
              </GridDivUnit>
              <GridDivUnit>
                <StyledHeaderText fontSize={2}>Education</StyledHeaderText>
                <span><StyledSpan fontSize={1.5}>Camosun College</StyledSpan> <StyledSpan fontSize={0.9}>(2022 - 2024)</StyledSpan></span>
                <span><StyledSpan fontSize={1.1} listItem dot>Diploma in Interactive Media Development Technology</StyledSpan> <StyledSpan fontSize={0.8}>(2024)</StyledSpan></span>
                <span><StyledSpan fontSize={1.1} listItem dot>Dean's Honour Roll</StyledSpan> <StyledSpan fontSize={0.8}>(2022 - 2024)</StyledSpan></span>
              </GridDivUnit>
              <GridDivUnit>
                <StyledHeaderText fontSize={2}>Experience</StyledHeaderText>
                <span><StyledSpan fontSize={1.5} listItem dot>Freelancer Web Developer</StyledSpan> <StyledSpan fontSize={0.9}>(2024 - Present)</StyledSpan></span>
                <span><StyledSpan fontSize={1.1} listItem dot>Mind Games Seasonal Sales Associate & Keyholder</StyledSpan> <StyledSpan fontSize={0.9}>(2024 - Present)</StyledSpan></span>
                <span><StyledSpan fontSize={1.1} listItem dot>Michaels Part-time Front-Staff</StyledSpan> <StyledSpan fontSize={0.9}>(2023 - 2024)</StyledSpan></span>
                <span><StyledSpan fontSize={1.1} listItem dot>Chicken 649 Part-time/Full-time Cashier</StyledSpan> <StyledSpan fontSize={0.9}>(2021 - 2022)</StyledSpan></span>
                <span><StyledSpan fontSize={1.1} listItem dot>Cobs Bread Part-time Sales Staff</StyledSpan> <StyledSpan fontSize={0.9}>(2019 - 2020)</StyledSpan></span>
              </GridDivUnit>
              </GridDiv>
            </StyledAboutGridItem>
            <StyledAboutGridItem index={4} move={'start'} sm={12} md={6} lg={6} xl={4} >
              <StyledHeaderText fontSize={2}>Soft Skills</StyledHeaderText>
              {
                softSkills.map((prop, index) => 
                  <StyledSkillItem key={`softSkill-${index}`} colorLight={colors[2]} colorDark={colors[3]}><StyledSpan fontSize={1.2} listItem>{prop}</StyledSpan></StyledSkillItem>
                )
              }
            </StyledAboutGridItem>
            <StyledAboutGridItem index={5} move={'start'} sm={12} md={6} lg={6} xl={4} >
              <StyledHeaderText fontSize={2}>Technical Skills</StyledHeaderText>
              <TechDiv>
                <TechDivUnit>
                  {
                    techSkills.map((prop, index) => 
                      index < 6 ? <StyledSkillItem key={`techSkill-${index}`} colorLight={colors[0]} colorDark={colors[1]}><StyledSpan fontSize={1.2} listItem>{prop}</StyledSpan></StyledSkillItem> : null
                    )
                  }
                </TechDivUnit>
                <TechDivUnit>
                  {
                    techSkills.map((prop, index) => 
                      index > 5 ? <StyledSkillItem key={`techSkill-${index}`} colorLight={colors[0]} colorDark={colors[0]}><StyledSpan fontSize={1.2} listItem>{prop}</StyledSpan></StyledSkillItem> : null
                    )
                  }
                </TechDivUnit>
              </TechDiv>
            </StyledAboutGridItem>
          </Grid>
        </div>
      </StyledAboutDiv>
    </StyledGiantBox>
  );
}
  

export default AboutPage;