import { MenuButton, HamburgerButton } from "./MenuButton";
import styled from "@emotion/styled";

const StyledHeader = styled.div`
  display: flex;
  position: relative;
  margin-top: 10vh;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const StyledMenuButtonsDiv = styled.div`
  @media (max-width: 768px) {
    display: ${props => props.isHamburgerOpen ? 'flex' : 'none'};
    flex-wrap: wrap;
    flex-direction: column;
    position: absolute;
    z-index: 199;
    margin-top: 0.5em;
  }
`;

const StyledMenuButton = styled(MenuButton)`
  color: black;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  animation-delay: ${props => (`${(props.index + 1) * 100}ms`)};
  background: ${props => props.bgColor};
  color: ${props => props.textColor};
  border: none;
  margin-right: 1em;
  height: 2em;
  width: 8em;
  text-align: center;
  align-items: center;
  font-size: 20px;
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  transition: all ease-in-out 200ms;

  &:hover {
    transform: translate(0, -3px);
    box-shadow: 1.5px 3px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    margin-top: 0.5em;
    height: 2em;
    min-width: 80vw;
    font-size: 26px;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 899px) {
    width: 6.5em;
  }
`;

const StyledHamburgerButton = styled(HamburgerButton)`
  cursor: pointer;
  background: linear-gradient(to right bottom, #333333, #000000);
  color: #FFFFFF;
  font-size: 20px;
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
  border: none;
  margin-right: 1em;
  height: 2em;
  width: 2em;
  text-align: center;
  align-items: center;
  font-size: 20px;
  opacity: 0;
  animation: fadeIn 400ms forwards;
  position: absolute;
  top: -40px;

  @media (min-width: 769px) {
    display: none;
  }
`;

export { StyledMenuButton, StyledHeader, StyledHamburgerButton, StyledMenuButtonsDiv };